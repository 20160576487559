header.header {
  top: 0;
  right: 0;
  z-index: 999;
  height: 77px;
  padding: 1em;
  display: flex;
  position: fixed;
  align-items: center;
  color: cssVar(header-text);
  background: cssVar(header-bg);
  justify-content: space-between;
  box-shadow: 0 0 4px rgba(#{cssVar(dark, $color)}, 0.25);

  .p-avatar {
    color: cssVar(header-text);
    background-color: cssVar(header-link);
  }

  @media screen and (max-width: 991px) {
    // padding-left: 1;
    justify-content: flex-start;
  }
}
